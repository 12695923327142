.formsList {
    width: 100%;
}

.formItem {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: rgb(var(--colour-tertiary));

    position: relative;

    border-left: 6px solid #1c3456;


    
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);


}

.formName {
    font-weight: bold;
    margin: 0 0 10px 10px;
}

/* don't display new form button on mobile */
.newFormButton {
    display: none;
}

.formOptions {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px 0 10px 0;
    padding: 0 10px 10px 0;
    cursor: pointer;
}

.formUpdatedAt {
    font-size: 0.9em;
    font-style: italic;
    margin: 0 0 10px 10px;
}

.formGoBtn {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 5px 5px 10px 0;
    padding: 5px;
    cursor: pointer;
}

.formGoBtn:hover {
    border-radius: 20px;
    background-color: #ccc;
}

@media screen and (min-width: 1025px) {
    .newFormButton {
        margin-left: auto;
        display: block;
        width: fit-content;
        padding: 10px 20px;
        border-radius: 10px;
        background-color: rgb(var(--colour-secondary));
        color: #fff;
        text-decoration: none;
        text-align: right;
    }
}