.saveBtn {
    display: block;
    margin-left: auto;
}

.previewPane {
    display: none;
}

/* Desktop */
@media (min-width: 1024px) {
    .editPane {
        display: inline-block;
        width: 49%;
        border-right: 1px solid #fff;
        overflow-y: auto;
        height: 80vh;
        margin-right: 1%;
    }

    .previewPane {
        display: inline-block;
        width: 49%;
        overflow-y: auto;
        height: 80vh;
    }
}