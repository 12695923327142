.header {
    position: sticky;
    top: 0;
    z-index: 99999;
}

.headerMobile, .headerLarge, .mobileMenu {
    background-color: rgb(28, 52, 86);
}

.headerLarge, .mobileMenuClosed {
    display: none;
}

.mobileMenuIcon {
    display: block;
    cursor: pointer;
    padding: 10px;
    width: fit-content;
    color: #fff;
}

.mobileMenu {
    padding: 1px 10px 10px;
    width: fit-content;
    border-radius: 0 0 15px 0;
    position: absolute;
}

.mobileMenu > ul, .headerLarge > ul {
    list-style: none;
    padding: 0;
}

.mobileMenu > ul:not(:first-child) {
    padding-top: 5px;
    border-top: 2px solid rgba(180, 180, 180, 0.5);
}

.mobileMenu > ul > li {
    padding: 15px;
}

.mobileMenu > ul > li, .headerLarge > ul > li {
    margin: 0 10px;
}

.mobileMenu > ul > li > a, .headerLarge > ul > li > a {
    color: white;
    text-decoration: none;
}

/* Tablet */
@media only screen and (min-width: 600px) {
    .headerMobile {
        display: none;
    }

    .headerLarge {
        padding: 0 40px;
    }

    .mobileMenuIcon, .mobileMenu {
        display: none;
    }

    .headerLarge { /* menuClosed should only apply to mobile */
        display: flex;
        justify-content: space-between;
    }

    .headerLarge > ul > li {
        display: inline-block;
        margin: 0 10px;
    }
}


/* Desktop */
@media only screen and (min-width: 768px) {
}