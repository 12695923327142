.content {
    margin: auto;
    width: 95%;
}

/* Tablet */
@media only screen and (min-width: 600px) {
    .content {
        width: 80%;
    }
}


/* Desktop */
@media only screen and (min-width: 1024px) {
    .content {
        width: 60%;
    }
}