
/* Input section */
.inputSection {
    /* background-color: rgb(var(--colour-quaternary)); */

    width: 95%;
    padding-bottom: 10px;
    margin: 10px auto;
    
    border-radius: 5px;
}

/* Input section header */
.inputHeader {
    margin-bottom: 10px;
}

.inputDetail {
    margin: 0 auto 10px auto;
    text-align: left;
    width: 90%;
}

.required {
    color: rgb(var(--colour-required));
}

/* Input container - set to sit in the middle  */ 
.input {
    width: 90%;
    margin: auto;
    display: flex;
}
/* Inputs - fill container and left align */
.input input, .input textarea, .input .selectInput {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}
/* Width auto for short inputs */
.input input[type=checkbox], .input input[type=radio], .input input[type=number], .input input[type=date], .input input[type=time] {
    width: auto;
}
/* Short value inputs centered */
.input input[type=number], .input input[type=date], .input input[type=time] {
    margin: 0 auto 10px auto;
}
/* Padding on all basic inputs */
.input input, .input textarea {
    padding: 5px 10px;
    border-radius: 5px;
}
/* 5 line height on textarea */
.input textarea {
    height: 80px;
}
/* Scaling on checkbox and radio */
input[type=checkbox], input[type=radio] {
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    margin: 5px 10px 10px 5px;
}
/* Signature canvas styling */
.sigCanvas {
    background-color: rgb(var(--colour-signature-box));
    
    border: 1px solid #000;
    border-radius: 5px;
    width: 85%;
    min-height: 15vh;
    aspect-ratio: auto;
}


/* Tablet */
@media only screen and (min-width: 600px) {
    .input, .inputDetail {
        width: 70%;
    }
}


/* Desktop */
@media only screen and (min-width: 768px) {
    .input, .inputDetail {
        width: 60%;
    }
}