.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form {
    text-align: center;
    width: 100%;
}

.section {
  border-top: 4px solid #1c3456;
    margin: auto;
    border-radius: 10px;
    background-color: rgb(var(--colour-tertiary));
    padding-bottom: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

   
}

.sectionHeader {
    padding-top: 10px;
}

.status-status, .status-error, .status-success {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.actionArea {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

/* .submitBtn {
    border: 0px;
    margin: 10px 0;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: rgb(var(--colour-secondary));
    color: #fff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    width: fit-content;
    box-shadow: 0 3px 0 #2c3e50;
} */
.submitBtn {
    font-size: 16px;
    border: 0px;
    background-color: rgb(var(--colour-secondary));
    margin-left: 5px;
    color: #ffffff;
    padding: 10px 20px;
    font-weight: 400;
    border-radius: 3px;
    box-shadow: 0 3px 0 #2c3e50;
    cursor: pointer;
  }

  /* input {
    border: none;
    background-color: var(--bit-bg-dent, #f6f6f6) !important;
    color: var(--bit-text-color-heavy, #2b2b2b);
    height: 100%;
    width: 100%;
    border-radius: 8px;
    &.error {
      border: var(--bit-error-color, #e62e5c) 1px solid;
      background-color: var(--bit-error-color, #e62e5c);
    }
    &:focus,
    &:active,
    &:hover {
      border: var(--bit-accent-color, #6c5ce7) 1px solid;
    }
  } */


.draftBtn {
    font-size: 16px;
    border: 0px;
    background-color: #1abc9c;
    margin-left: 5px;
    color: #ffffff;
    padding: 10px 20px;
    font-weight: 400;
    border-radius: 3px;
    box-shadow: 0 3px 0 #2c3e50;
    cursor: pointer;
  }