@keyframes slideUp {
    0% {
        transform: translateY(var(--item-height1));
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(var(--item-height2));
    }
    100% {
        transform: translateY(0);
    }
}

.form {
    width: 95%;
}

.formName {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    margin: auto auto 20px auto;
    padding: 8px;
    text-align: center;
}

.section {
    margin: auto;
    border-radius: 10px;
    background-color: rgb(var(--colour-tertiary));
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.sectionName {
    display: block;
    width: 40%;
    margin: 0 auto 5px auto;
    font-size: 1.2em;
    padding: 8px;
    text-align: center;
}

.inputSection {
    background-color: rgb(var(--colour-quaternary));

    width: 95%;
    margin: auto;
    
    border-radius: 5px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.col {
    display: inline-block;
    width: 46%;
    margin: 0 2%;
}

.inputLabel {
    display: block;
    margin-bottom: 3px;
}

.inputName, .inputType {
    width: 100%;
}

.inputName {
    font-size: 1em;
    padding: 8px;
    margin-bottom: 21px;
}

.inputReq, .inputNA {
    margin-left: 10px;
}

.contextBtn {
    display: block;
    cursor: pointer;
    margin: 5px 5px 5px auto;
    width: fit-content;
}

.inputSection > .contextBtn {
    visibility: hidden;
}

.section > .contextBtn, .inputSection:hover > .contextBtn {
    color: var(--colour-text);
    visibility: visible;
}

.slideUp {
    animation: slideUp 0.5s ease-in-out;
}

.slideDown {
    animation: slideDown 0.5s ease-in-out;
}

.contextBtnSection {
    margin: 10px auto 0 10px;
}

.contextBtnDialog {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
    background-color: rgb(var(--colour-quaternary));
    z-index: 1000;
    display: none;
}

.contextBtnDialogOpen {
    display: block;
}

.inputError {
    border-color: red;
}

.inputDetailbox {
    margin: 0 2%;
    width: 95%;
}

.clickable {
    cursor: pointer;
}

.options {
    width: 100%;
}

.optionLabel {
    width: 75%;
}

.optionWeight {
    width: 10%;
}

.optionActions {
    width: 15%;
}

.options th {
    text-align: left;
    padding: 2px 5px
}

.options td {
    padding: 2px 5px;
}

.options input {
    width: 100%;
}