.adminsTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    margin: 0;
    padding: 0;
    table-layout: fixed;
}

.usersTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    margin: 0;
    padding: 0;
    table-layout: fixed;
}

.switch > input {
    width: 0;
    height: 0;
    visibility: hidden;
}

.switch > label {
    cursor: pointer;
    text-indent: -9999px;
    width: 52px;
    height: 27px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

.switch > label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

/* strech to half width before sliding over */
.switch > label:active:after {
    width: 50%;
}

.switch > input:checked + label {
    background: rgb(var(--colour-confirm));
}

.switch > input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}