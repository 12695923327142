.panels {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
}

.sidePanel {
    width: 12%;
    padding: 0 20px;
    background-color: rgb(var(--colour-primary));
    height: calc(100vh - 51px);
}

.sidePanel > ul {
    list-style-type: none;
    padding: 0;
    margin: 40px 0 0 0;
    display: flex;
    flex-direction: column;
}

.sidePanel > ul > li {
    padding: 15px 10px 10px 10px;
    color: #fff;
    border-radius: 15px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
}

.sidePanel > ul > li:hover, .selectedTab {
    background-color: #456189;
    color: #40E0D0 !important; /* why is this !important needed? */
}

.mainPanel {
    width: 80%;
    padding: 0 30px;
    height: calc(100vh - 51px);
    overflow-y: auto;
}

.panelHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 20px 0;
}

.saveBtn {
    margin: 10px 0;
    padding: 8px 15px;
    background-color: rgb(var(--colour-secondary));
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
}

.saveBtn:active {
    background-color: rgba(var(--colour-secondary), 0.75);
}

.panelContent {
    border-radius: 20px;
    padding: 1px 20px 20px 20px;
    box-shadow: 0 1px 200px 0 rgba(126, 126, 126, 0.1);
}