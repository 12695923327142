

body {
    font-family: Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    color: #444;
  }
  

.table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

/* scrollbar */
.table::-webkit-scrollbar {
    height: 10px;
    width: 100%;
}

.table::-webkit-scrollbar-thumb {
    background-color: rgb(var(--colour-primary));
    border-radius: 10px;
}
/* scrollbar end */

.table caption {
    text-align: left;
}

.table thead {
    background-color: rgb(var(--colour-primary));
}

.table thead th {
    font-weight: 600;
    padding: 0.5rem 1rem;
    text-align: left;
}

.table tbody tr:nth-child(odd) {
    background-color: var(--colour-grey-100);
}

.table tbody tr:hover {
    background-color: #fff;
    cursor: pointer;
}

.table tbody td {
    padding: 0.5rem 1rem;
}

.image {
    height: 2rem;
}

.submitBtn {
    border: 0px;
    margin: 10px 0;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: rgb(var(--colour-secondary));
    color: #fff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    width: fit-content;
}

.white {
    
    color: #fff;
}

.submitBtn {
    border: 0px;
    margin: 10px 0;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: rgb(var(--colour-secondary));
    color: #fff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    width: fit-content;
}

.white {
    
    color: #fff;
}



