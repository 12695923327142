.BlockUIConfirm {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	width: 100vw;
	z-index: 50;

}

.blockuiMask {

	width: 100%;
	height: 100%;



	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;

	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	opacity: 0.8;
	-webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	-moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	-o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.7); 


    

}

.RowDialogBody {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 100;
	transform: translate(-50%, -50%);
	width: 100%;
	max-width: 450px;
	opacity: 1;
	background-color: white;
	border-radius: 4px;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	animation: show 450ms cubic-bezier(.25,.8,.25,1) forwards;
	/* animation: hide 150ms cubic-bezier(0.4, 0, 0.2, 1) forwards; */
}

.RowDialogBody > div:not(.confirmBody) {
	padding: 8px 10px;
}

.confirm-header {
	/* width: 100%; */
	border-radius: 4px 4px 0 0;
	font-size: 13pt;
	font-weight: bold;
	margin: 0;
	text-align: center;
}

.row-dialog-hdr-success {
	border-top: 4px solid #456189;
	border-bottom: 1px solid transparent;
}

.row-dialog-hdr-info {
	border-top: 4px solid #5bc0de;
	border-bottom: 1px solid transparent;
}

.confirmBody{
	border-top: 1px solid #ccc;
	padding:20px 10px;
	border-bottom: 1px solid #ccc;
	text-align: center;

}
 .btn-holder{
	
	text-align: center;
}

.confirm-btn-panel {
	width: 100%;
}
.row-dialog-btn {
	cursor: pointer;
}
.btn{

    display: inline-block;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: .5rem 1rem;
    font-size: 1rem;
    border-radius: .25rem;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;

}
.btn-naked {
	background-color: transparent;
}

.btn-success {
    color: #fff;
    background-color: #5cb85c !important;
    border-color: #5cb85c !important;
	width: 80%;
}

.dummy-positioning {
	width: 100%;
	height: 100vh;
	align-items: center;
	justify-content: center;
  }
  
  .success-icon {
	display: inline-block;
	width: 8em;
	height: 8em;
	font-size: 20px;
	border-radius: 50%;
	border: 4px solid #96df8f;
	background-color: #fff;
	position: relative;
	overflow: hidden;
	transform-origin: center;
	-webkit-animation: showSuccess 180ms ease-in-out;
			animation: showSuccess 180ms ease-in-out;
	transform: scale(1);
  }
  
  .success-icon__tip, .success-icon__long {
	display: block;
	position: absolute;
	height: 4px;
	background-color: #96df8f;
	border-radius: 10px;
  }
  .success-icon__tip {
	width: 2.4em;
	top: 4.3em;
	left: 1.4em;
	transform: rotate(45deg);
	-webkit-animation: tipInPlace 300ms ease-in-out;
			animation: tipInPlace 300ms ease-in-out;
	-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
	-webkit-animation-delay: 180ms;
			animation-delay: 180ms;
	visibility: hidden;
  }
  .success-icon__long {
	width: 4em;
	transform: rotate(-45deg);
	top: 3.7em;
	left: 2.75em;
	-webkit-animation: longInPlace 140ms ease-in-out;
			animation: longInPlace 140ms ease-in-out;
	-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
	visibility: hidden;
	-webkit-animation-delay: 440ms;
			animation-delay: 440ms;
  }
  
  @-webkit-keyframes showSuccess {
	from {
	  transform: scale(0);
	}
	to {
	  transform: scale(1);
	}
  }
  
  @keyframes showSuccess {
	from {
	  transform: scale(0);
	}
	to {
	  transform: scale(1);
	}
  }
  @-webkit-keyframes tipInPlace {
	from {
	  width: 0em;
	  top: 0em;
	  left: -1.6em;
	}
	to {
	  width: 2.4em;
	  top: 4.3em;
	  left: 1.4em;
	  visibility: visible;
	}
  }
  @keyframes tipInPlace {
	from {
	  width: 0em;
	  top: 0em;
	  left: -1.6em;
	}
	to {
	  width: 2.4em;
	  top: 4.3em;
	  left: 1.4em;
	  visibility: visible;
	}
  }
  @-webkit-keyframes longInPlace {
	from {
	  width: 0em;
	  top: 5.1em;
	  left: 3.2em;
	}
	to {
	  width: 4em;
	  top: 3.7em;
	  left: 2.75em;
	  visibility: visible;
	}
  }
  @keyframes longInPlace {
	from {
	  width: 0em;
	  top: 5.1em;
	  left: 3.2em;
	}
	to {
	  width: 4em;
	  top: 3.7em;
	  left: 2.75em;
	  visibility: visible;
	}
  }


@keyframes show{
	from {
	  opacity: 0;
	  transform: translateY(-100%, -50%);
	}
	to {
	  opacity: 1;
	  transform: translateY(50% -50%);
	}
  }
