.googleLogin {
    position: absolute;
    top: 50%;
    left: calc(50% - 96.5px);
    margin: auto;
    width: 191px;
    height: 46px;
    background-image: url("../../assets/google/btn_google_signin_dark_normal_web@2x.png");
    background-size: contain;
    cursor: pointer;
    border-radius: 4px;
}

.googleLogin:hover {
    background-image: url("../../assets/google/btn_google_signin_dark_focus_web@2x.png");
}

.googleLogin:active {
    background-image: url("../../assets/google/btn_google_signin_dark_pressed_web@2x.png");
}