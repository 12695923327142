.expandableBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 20px 0;
    background: var(--button-blue-gradient);
    color: #fff;
    padding: 10px 40px;
    border-radius: 10px;
}

.title {
    margin: 0;
}

.endSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 130px;
}

.expandIcon {
    margin-right: 10px;
    cursor: pointer;
    font-weight: 900;
    font-size: 30px;
}

@keyframes slideIn {
    0% {
        transform: translateY(-5%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.content {
    animation: 0.3s ease-out 0s 1 slideIn;
}