:root {
    --colour-primary: 35, 61, 98; /* #233D62 */
    --colour-secondary: 244, 141, 50; /* #F48D32 */

    --button-blue-gradient: linear-gradient(90deg, #456189, rgb(var(--colour-primary)));
    --fake-white-shadow: 0 1px 200px 0 rgba(126, 126, 126, 0.1);

    --colour-tertiary: 228, 231, 235;
    --colour-quaternary: 153, 153, 153;

    --colour-background: 250, 250, 250;
    

    --colour-signature-box: 187, 187, 187;

    --colour-required: 255, 0, 0;

    --colour-text: #000;

    --colour-cancel: 255, 105, 97;
    --colour-confirm: 119, 221, 119;
    --colour-blue: #00f;

    --item-height1: -100%;
    --item-height2: 100%;
}