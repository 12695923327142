/* the UL that contains the tabs */
.integrationTabs {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-bottom: 20px;
    list-style: none;
    padding: 0;
    border-radius: 20px;
    box-shadow: var(--fake-white-shadow);
    background-color: #fff;
}

.selectedIntegration, .unselectedIntegration {
  padding: 10px 35px 10px 35px;
  cursor: pointer;
  border-radius: 20px;
  font-weight: 400;
  margin-right: 10px;
}

.selectedIntegration, .unselectedIntegration:hover {
  background: var(--button-blue-gradient);
  color: #fff;
}

.unselectedIntegration {
  background-color: #fff;
  color: #999;
}