@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-5%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.contextMenu {
    position: absolute;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 20px 0 20px 20px;
    animation-name: slideIn;
    animation-duration: 0.5s;
}

.item > * {
    display: block;
    padding: 5px 10px;
    margin: 10px 0;
}

.item {
    border-radius: 5px;
    cursor: pointer;
}

.item:hover {
    background-color: #eee;
    transition: all 0.3s ease-out;
}