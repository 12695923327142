.connectionItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;
}

.connectionItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
}

.connectionName {
    font-weight: bold;
}

.connectionBtns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.actionBtn {
    padding: 10px;
    color: rgb(var(--colour-primary));
    background-color: #fff;
    border: 2px solid rgb(var(--colour-primary));
    border-radius: 5px;
    margin: 0 20px;
    cursor: pointer;
    text-decoration: none;
    width: 5em;
    text-align: center;
}

.actionBtn:hover {
    color: #fff;
    background-color: rgb(var(--colour-secondary));
    border-color: rgb(var(--colour-secondary));
}

.statusBarRed, .statusBarGreen {
    border-radius: 5px;
    color: #fff;
    text-align: center;
    padding: 12px;
    width: 7em;
}

.statusBarRed {
    background-color: rgb(var(--colour-cancel));
}

.statusBarGreen {
    background-color: rgb(var(--colour-confirm));
}

.statusIcon {
    display: none;
}

@media screen and (min-width: 768px) {
    .connectionItems {
        font-size: 16px;
    }

    .actionBtn {
        width: 5em;
    }

    .statusBarRed, .statusBarGreen {
        width: 12em;
    }

    .statusIcon {
        display: block;
        font-size: 16px;
        text-align: right;
        float: right;
        margin-top: 1px;
    }
}