
  .sectionComponent {
    /* max-width: 450px; */
    margin: 28px auto;
    padding: 8px;
    /* border: 2px dashed rgb(44 86 145);
    background-color: #e4edff; */
  }


  .complabelComponent{
    display: inline-block;
    position: absolute;
    transform: translateY(-78px) translateX(0px);
    z-index: 2;
    
    /* background-color: whitesmoke; */
    /* padding: 1px 3px;
    border: 1px solid gainsboro; */
  }
  .coverComponent{
  margin-top: 30px;
  padding-top: 10px;
}
.coverComponent span{
font-weight: 400;
}

  
  .flex_container {
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: row; 
    gap: 20px; 
    justify-content: center;
    padding-top: 40px;
  }
  
  .tagContainer {
    color: white; 
    font-size: 16px; 
    background-color: SteelBlue; 
    border: 4px solid SteelBlue; 
    /* background-color: Crimson; 
    border: 4px solid Crimson;  */
    /* border-radius: 10px;   */
padding: 2px;
  }

  
  .tagDarkTurquoise {

    background-color: DarkTurquoise; 
    border:4px solid DarkTurquoise; 

  } 
  
  .tagMediumSeaGreen {

    background-color: MediumSeaGreen; 
    border:4px solid MediumSeaGreen; 

  }
  
  .tagSteelBlue {

    background-color: SteelBlue; 
    border: 4px solid SteelBlue; 

  }
  .tagOrange {
    color: white; 
    font-size: 16px; 
    background-color: Orange; 
    border:4px solid Orange; 
    /* border-radius: 18px; */
    line-height: 1.9em;
    margin: 0px;
  }
    
  .tag_6 { 
    color: white; 
    font-size: 16px; 
    background-color: Plum; 
    border:4px solid Plum; 
    border-radius: 18px;
  }
    
  .tag_7 {
    color: white; 
    font-size: 16px; 
    background-color: BurlyWood; 
    border: 4px solid BurlyWood; 
    border-radius: 18px;
  }
  
  .tag_8 {
    color: white; 
    font-size: 16px; 
    background-color: MediumPurple;
    border: 4px solid MediumPurple; 
    border-radius: 18px
  }
  
  .tag_9 {
    color: white; 
    font-size: 16px; 
    background-color: HotPink;
    border: 4px solid HotPink; 
    border-radius: 18px
  }
  
  .tag_10 {
    color: white; 
    font-size: 16px; 
    background-color: Teal;
    border: 4px solid Teal; 
    border-radius: 18px
  }
  
  .tag_11 {
    color: white; 
    font-size: 16px; 
    background-color: RoyalBlue;
    border: 4px solid RoyalBlue; 
    border-radius: 18px
  }
  
  .tag_12 {
    color: white; 
    font-size: 16px; 
    background-color: DarkCyan;
    border: 4px solid DarkCyan; 
    border-radius: 18px
  }
  
  .tag_13 {
    color: white; 
    font-size: 16px; 
    background-color: MediumVioletRed;
    border: 4px solid MediumVioletRed; 
    border-radius: 18px
  }
  
  .tag_14 {
    color: white; 
    font-size: 16px; 
    background-color: MediumPurple;
    border: 4px solid MediumPurple; 
    border-radius: 18px
  }
  
  .tag_15 {
    color: white; 
    font-size: 16px; 
    background-color: MediumOrchid;
    border: 4px solid MediumOrchid; 
    border-radius: 18px
  }
  